<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOTE DE PRODUCTOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-boxes fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaLote.length}}</span>
                            <br>
                            <span class="text-muted">Lote de productos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de Lote de productos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('008-PRO-LDP','c') == 1" cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarLote = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo lote de productos</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Lote de productos registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaLote" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button v-if="checkPermissions('008-PRO-LDP','u') == 1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarLote(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button v-if="checkPermissions('008-PRO-LDP','d') == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarLote(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarLote" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo lote de productos</span>
            </h6>
            <CButtonClose @click="modalRegistrarLote = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarLote)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="n° de Lote" :rules="{required: true,numeric: true}" v-slot="validationContext">
                            <b-form-group label="N° de lote:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="datosLote.nLote"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosLote.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="orden de compra" v-slot="validationContext">
                            <b-form-group label="Orden de compra:" class="mb-2">
                                <b-form-file ref="file1" v-model="datosLote.documentoOrdenCompra" v-on:change="handleFileUpload($event,1)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="orden de servicio" v-slot="validationContext">
                            <b-form-group label="Orden de Servicio:" class="mb-2">
                                <b-form-file ref="file2" v-model="datosLote.documentoOrdenServicio" v-on:change="handleFileUpload($event,2)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="orden de trabajp" v-slot="validationContext">
                            <b-form-group label="Orden de trabajo:" class="mb-2">
                                <b-form-file ref="file3" v-model="datosLote.documentoOrdenTrabajo" v-on:change="handleFileUpload($event,3)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="referencias" v-slot="validationContext">
                            <b-form-group label="Referencias:" class="mb-2">
                                <b-form-textarea v-model="datosLote.referencias" :state="getValidationState(validationContext)" placeholder="Ingrese las referencias" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarLote = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarLote" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar lote de productos</span></h6>
            <CButtonClose @click="modalActualizarLote = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarLote)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="n° de Lote" :rules="{required: true,numeric: true}" v-slot="validationContext">
                            <b-form-group label="N° de lote:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="datosActualizarLote.nLote"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosActualizarLote.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="orden de compra" v-slot="validationContext">
                            <b-form-group label="Orden de compra:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarLote.urlDocumentoCompra != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarLote.urlDocumentoCompra )" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file  ref="fileActualizar1" v-on:change="ActualizarhandleFileUpload($event,1)" :state="getValidationState(validationContext)" :placeholder="datosActualizarLote.nombreDocumentoCompra ? datosActualizarLote.nombreDocumentoCompra : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosActualizarLote.nombreDocumentoCompra ? datosActualizarLote.nombreDocumentoCompra : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="datosActualizarLote.documentoOrdenCompra"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider name="orden de servicio" v-slot="validationContext">
                            <b-form-group label="Orden de Servicio:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarLote.urlDocumentoServicio != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarLote.urlDocumentoServicio )" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file  ref="fileActualizar2" v-on:change="ActualizarhandleFileUpload($event,2)" :state="getValidationState(validationContext)" :placeholder="datosActualizarLote.nombreDocumentoServicio ? datosActualizarLote.nombreDocumentoServicio : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosActualizarLote.nombreDocumentoServicio ? datosActualizarLote.nombreDocumentoServicio : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="datosActualizarLote.documentoOrdenServicio"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                   
                    </b-col>
                    <b-col md="6">
                            <validation-provider name="orden de trabajp" v-slot="validationContext">
                            <b-form-group label="Orden de trabajo:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarLote.urlDocumentoTrabajo != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarLote.urlDocumentoTrabajo )" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file  ref="fileActualizar3" v-on:change="ActualizarhandleFileUpload($event,3)" :state="getValidationState(validationContext)" :placeholder="datosActualizarLote.nombreDocumentoTrabajo ? datosActualizarLote.nombreDocumentoTrabajo : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosActualizarLote.nombreDocumentoTrabajo ? datosActualizarLote.nombreDocumentoTrabajo : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="datosActualizarLote.documentoOrdenTrabajo"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="referencias" v-slot="validationContext">
                            <b-form-group label="Referencias:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarLote.referencias" :state="getValidationState(validationContext)" placeholder="Ingrese las referencias" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarLote = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            listaLote: [],
            camposLote: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nLote",
                    label: "N° de Lote",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "referencias",
                    label: "Referencias",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosLote: {
                idCliente: '',
                nLote: '',
                codigo: '',
                referencias: '',
                documentoOrdenCompra: null,
                documentoOrdenServicio: null,
                documentoOrdenTrabajo: null,
            },
            datosActualizarLote: {
                idLoteProducto: '',
                idCliente: '',
                nLote: '',
                codigo: '',
                referencias: '',
                documentoOrdenCompra: null,
                urlDocumentoCompra: '',
                nombreDocumentoCompra: '',
                documentoOrdenServicio: null,
                urlDocumentoServicio: '',
                nombreDocumentoServicio: '',
                documentoOrdenTrabajo: null,
                urlDocumentoTrabajo: '',
                nombreDocumentoTrabajo: '',
            },
            modalRegistrarLote: false,
            modalActualizarLote: false,

        }
    },
    methods: {
        handleFileUpload(e, i) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${i}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file${i}`].reset();
                    return;
                }

            }
        },

             ActualizarhandleFileUpload(e, i) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`fileActualizar${i}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`fileActualizar${i}`].reset();
                    return;
                }

            }
        },

        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        descargarDocumento(url) {
            window.open(url)
        },

        listarLoteProductos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-lote-productos", {
                        params: {
                            idCliente: me.datosLote.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaLote = response.data;
                    me.filasTotales = me.listaLote.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarLote() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();

            formData.append("file1", me.datosLote.documentoOrdenCompra);
            formData.append("file2", me.datosLote.documentoOrdenServicio);
            formData.append("file3", me.datosLote.documentoOrdenTrabajo);

            formData.append("folder", 'proyectos/loteProductos');

            formData.append("idCliente", me.datosLote.idCliente);

            formData.append("nLote", me.datosLote.nLote);
            formData.append("codigo", me.datosLote.codigo);
            formData.append("referencias", me.datosLote.referencias);

            formData.append("nombreDocumentoCompra", '');
            formData.append("nombreDocumentoServicio", '');
            formData.append("nombreDocumentoTrabajo", '');

            formData.append("urlDocumentoCompra", '');
            formData.append("urlDocumentoServicio", '');
            formData.append("urlDocumentoTrabajo", '');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-lote-productos",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.modalRegistrarLote = false;
                    me.listarLoteProductos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarLote() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();

            formData.append("file1", me.datosActualizarLote.documentoOrdenCompra);
            formData.append("file2", me.datosActualizarLote.documentoOrdenServicio);
            formData.append("file3", me.datosActualizarLote.documentoOrdenTrabajo);

            formData.append("folder", 'proyectos/loteProductos');

            formData.append("idCliente", me.datosActualizarLote.idCliente);
            formData.append("idLoteProducto", me.datosActualizarLote.idLoteProducto);

            formData.append("nLote", me.datosActualizarLote.nLote);
            formData.append("codigo", me.datosActualizarLote.codigo);
            formData.append("referencias", me.datosActualizarLote.referencias);

            formData.append("nombreDocumentoCompra", me.datosActualizarLote.nombreDocumentoCompra);
            formData.append("nombreDocumentoServicio", me.datosActualizarLote.nombreDocumentoServicio);
            formData.append("nombreDocumentoTrabajo", me.datosActualizarLote.nombreDocumentoTrabajo);

            formData.append("urlDocumentoCompra", me.datosActualizarLote.urlDocumentoCompra);
            formData.append("urlDocumentoServicio", me.datosActualizarLote.urlDocumentoServicio);
            formData.append("urlDocumentoTrabajo", me.datosActualizarLote.urlDocumentoTrabajo);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-lote-productos",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.modalActualizarLote = false;
                    me.listarLoteProductos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        eliminarLote(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el lote?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-lote-producto", {
                                idLoteProducto: param.item.idLoteProducto
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarLoteProductos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarLote(param) {
            this.datosActualizarLote.idLoteProducto = param.item.idLoteProducto;
            this.datosActualizarLote.codigo = param.item.codigo;
            this.datosActualizarLote.nLote = param.item.nLote;
            this.datosActualizarLote.referencias = param.item.referencias;
            this.datosActualizarLote.nombreDocumentoCompra = param.item.nombreDocumentoCompra;
            this.datosActualizarLote.urlDocumentoCompra = param.item.urlDocumentoCompra;
            this.datosActualizarLote.nombreDocumentoServicio = param.item.nombreDocumentoServicio;
            this.datosActualizarLote.urlDocumentoServicio = param.item.urlDocumentoServicio;
            this.datosActualizarLote.nombreDocumentoTrabajo = param.item.nombreDocumentoTrabajo;
            this.datosActualizarLote.urlDocumentoTrabajo = param.item.urlDocumentoTrabajo;
            this.modalActualizarLote = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarLote() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosLote.codigo = '';
            this.datosLote.nLote = '';
            this.datosLote.referencias = '';
            this.datosLote.documentoOrdenCompra = null;
            this.datosLote.documentoOrdenServicio = null;
            this.datosLote.documentoOrdenTrabajo = null;

        },
        resetModalActualizarLote() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosActualizarLote.documentoOrdenTrabajo = null;
            this.datosActualizarLote.documentoOrdenServicio = null;
            this.datosActualizarLote.documentoOrdenCompra = null;
        },
    },
    watch: {
        modalRegistrarLote: function (val) {
            if (val == false) {
                this.resetModalRegistrarLote();
            }
        },
        modalActualizarLote: function (val) {
            if (val == false) {
                this.resetModalActualizarLote();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosLote.idCliente = this.datosActualizarLote.idCliente = user.uidClient;
            this.listarLoteProductos();
        }
    }

}
</script>
